import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faCheckSquare, faCoffee } from "@fortawesome/free-solid-svg-icons";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import { AboutMeComponent } from "./component/AboutMeComponent";
import { HomePageComponent } from "./component/HomePageComponent";
import { PrivacyPolicy } from "./component/PrivacyPolicyCompnent";
import { ResumeComponent } from "./component/ResumeComponent";

library.add(fab, faCheckSquare, faCoffee);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Navigate to="/home-page" />} />
        <Route exact path="/home-page" element={<HomePageComponent />} />
        <Route exact path="/about-me" element={<AboutMeComponent />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/resume" element={<ResumeComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
