import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import { ReactComponent as HandshakeIcon } from "../resources/handshake_icon.svg";
import { ReactComponent as SendIcon } from "../resources/send_icon.svg";
import working_fra from "../resources/working_fra.png";
import { FilesBarComponent } from "./FilesBarComponent.jsx";

export const HomePageComponent = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  var data = require("../resources/config.json");

  let serviceId = data.serviceId;
  let templateId = data.templateId;
  let publicKey = data.publicKey;

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      email.trim().length === 0 ||
      firstName.trim().length === 0 ||
      message.trim().length === 0
    ) {
      alert("Fill the form before sending the email");
      return;
    }

    if (!isChecked) {
      alert("You have to accept the Privacy Policy before sending an email");
      return;
    }

    // const button = document.getElementById("confirm-button");
    // const buttonDiv = document.getElementById("send-email-div");
    // button.value = "Sending...";
    // buttonDiv.className = "sending-email-button";

    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        // button.value = "Send";
        // buttonDiv.className = "send-mail-button";
        const inputs = document.querySelectorAll(
          "#from_name, #form_last_name, #from_email, #message"
        );
        inputs.forEach((input) => {
          input.value = "";
        });

        setIsChecked(false);

        alert("Email sent!");
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <>
      <FilesBarComponent />
      <div className="content">
        <div className="content-title">
          <div className="title-container">
            <p>Hello,</p>
            <p>
              This is{" "}
              <span style={{ color: "#007acc" }}>Francesco Gastone</span>, I'm a
              Professional Software Developer
            </p>
          </div>
          <img src={working_fra} alt="working_fra"></img>
        </div>
        <div className="container">
          <div className="rectangle-left">
            <p>
              If you have a question or you want to request for quotation use
              the form. I'll be happy to answer!
            </p>
            <HandshakeIcon />
          </div>
          <div className="rectangle-right">
            <div className="rectangle-right-title">
              <h3>Get in touch with me!</h3>
            </div>
            <form className="form-section" ref={form} onSubmit={sendEmail}>
              <div className="form-row">
                <div className="form-group">
                  <span>First Name</span>
                  <input
                    name="from_name"
                    id="from_name"
                    className="form-field"
                    type="text"
                    value={firstName !== undefined ? firstName : ""}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <span>Last Name</span>
                  <input
                    name="from_last_name"
                    id="from_last_name"
                    className="form-field"
                    type="text"
                    value={lastName !== undefined ? lastName : ""}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <span>Email</span>
                  <input
                    name="from_email"
                    id="from_email"
                    className="form-field"
                    type="text"
                    value={email !== undefined ? email : ""}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <span>Contanct Number</span>
                  <input
                    className="form-field"
                    type="text"
                    value={contactNumber !== undefined ? contactNumber : ""}
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <span>Message</span>
                  <textarea
                    name="message"
                    id="message"
                    className="form-field"
                    type="text"
                    value={message !== undefined ? message : ""}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
              <div className="privacy-policy-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={setIsChecked}
                  />
                  I have read and understood the
                  <a href="/privacy-policy"> Privacy Policy</a>
                </label>
              </div>
              <div className="contact-me-btn" onClick={sendEmail}>
                <SendIcon />
                <p>Contact Me</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
