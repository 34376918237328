import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CodeIcon } from "../resources/code_icon.svg";
import { ReactComponent as GitHubIcon } from "../resources/github_icon.svg";
import { ReactComponent as LinkedInIcon } from "../resources/linkedin_icon.svg";
import { ReactComponent as ReactJsIcon } from "../resources/react_js_icon.svg";
import { ReactComponent as TsIcon } from "../resources/ts_icon.svg";
import { ReactComponent as InstagramIcon } from "../resources/white_instagram_logo.svg";

export const FilesBarComponent = () => {
  const [activeFile, setActiveFile] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const navigate = useNavigate();

  const toggleList = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleFileClick = (fileName) => {
    switch (fileName) {
      case "index.html":
        navigate("/home-page");
        break;
      case "about.ts":
        navigate("/about-me");
        break;
      case "resume.tsx":
        navigate("/resume");
        break;
      default:
        console.warn(`Unhandled action type: ${fileName}`);
        break;
    }
  };

  useEffect(() => {
    const path = window.location.pathname.split("/").pop();
    if (path.includes("home-page")) {
      setActiveFile("index.html");
    } else if (path.includes("about-me")) {
      setActiveFile("about.ts");
    } else if (path.includes("resume")) {
      setActiveFile("resume.tsx");
    }
  }, []);

  return (
    <>
      <div className="top-menu">
        <p>File</p>
        <p>Edit</p>
        <p>Selection</p>
        <p>View</p>
        <p>Go</p>
        <p>Run</p>
        <p>Terminal</p>
        <p>Help</p>
      </div>
      <div className="left-menu">
        <h3>EXPLORER</h3>
        <div onClick={toggleList}>
          <p>{isMenuVisible ? " - " : " + "} Portfolio</p>
        </div>
        {isMenuVisible && (
          <>
            <div
              className={activeFile === "index.html" ? "active" : ""}
              onClick={() => handleFileClick("index.html")}
            >
              <CodeIcon />
              <p>index.html</p>
            </div>
            <div
              className={activeFile === "about.ts" ? "active" : ""}
              onClick={() => handleFileClick("about.ts")}
            >
              <TsIcon />
              <p>about.ts</p>
            </div>
            <div
              className={activeFile === "resume.tsx" ? "active" : ""}
              onClick={() => handleFileClick("resume.tsx")}
            >
              <ReactJsIcon />
              <p>resume.tsx</p>
            </div>
          </>
        )}
      </div>

      <div className="files-menu">
        <div
          className={activeFile === "index.html" ? "active" : ""}
          onClick={() => handleFileClick("index.html")}
        >
          <CodeIcon />
          <p>index.html</p>
        </div>
        <div
          className={activeFile === "about.ts" ? "active" : ""}
          onClick={() => handleFileClick("about.ts")}
        >
          <TsIcon />
          <p>about.ts</p>
        </div>
        <div
          className={activeFile === "resume.tsx" ? "active" : ""}
          onClick={() => handleFileClick("resume.tsx")}
        >
          <ReactJsIcon />
          <p>resume.tsx</p>
        </div>
      </div>

      <div className="bot-menu">
        <div className="social-icons-container">
          <a href="https://www.instagram.com/_francescogastone_/">
            {" "}
            <InstagramIcon />{" "}
          </a>
          <a href="https://github.com/gastonefrancesco">
            {" "}
            <GitHubIcon />{" "}
          </a>
          <a href="https://www.linkedin.com/in/francesco-gastone-50663a20b/">
            {" "}
            <LinkedInIcon />{" "}
          </a>
        </div>
        <p>© 2024 - Francesco Gastone</p>
      </div>
    </>
  );
};
